import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';
import Cookie from 'js-cookie';
import { loadLanguageAsync } from '@/i18n';
import { URLS } from '@/common/js/constant/index';
import { Modal } from 'ant-design-vue';
import { get } from '@/plugins';
import routesCommon from './common';
import routesDesktop from './desktop';
import { getLocale } from '@/utils/core';
import { i18n } from '../i18n';

const locale = getLocale();

const router = createRouter({
    history: createWebHistory(locale !== 'zh-TW' ? locale : ''),
    routes: [...routesCommon, ...routesDesktop],
    scrollBehavior(to: any, from: any, savedPosition: any) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach(async (to: any, from: any, next: any) => {
    const isLogin = Cookie.get('isLogin') === '1';
    Modal.destroyAll();

    // 已登录返回登录页面则跳转到首页
    if (to.name === 'login' && isLogin) {
        router.push({ path: 'home' });
        return;
    }

    // 需要验证登录
    if (to.meta.needAuth) {
        // 如未登录则跳转登录页
        if (!isLogin) {
            router.push({ name: 'login' });
            return;
        }
        // 第一次访问则获取用户信息
        if (!(store.state as any).user.user_guid) {
            await _getUserInfo();
        }
    }

    // 这段真不需要
    // 如果权限未通过则跳转首页,
    // if (to.meta.permission && !to.meta.permission()) {
    //   router.push({ path: 'home' });
    // }

    loadLanguageAsync(locale).then(() => {
        document.title = (getRouteTitle(to) || i18n.global.t('76171')) + ' - ' + i18n.global.t('76172');
        return next();
    });
    next();
});

function getRouteTitle(to: any) {
    if (to.meta.title) {
        return i18n.global.t((to.meta.title || '') as string);
    }
}

// 获取并设置用户信息
async function _getUserInfo() {
    const res = await get(URLS.getUserInfo);

    if (res) {
        // 更新到vuex
        store.commit('user/updateUserInfo', res);
    } else {
        Cookie.remove('isLogin');
        router.push({ name: 'login' });
    }
}

export default router;
